import { useContext, useEffect, useState } from 'react'
import { UserContext } from 'library/context/userContext'
import { SiteContext } from 'library/context/siteContext'

import Meta from 'components/partials/meta'
import Spotlight from 'components/partials/spotlight'
import Footer from 'components/structure/footer'
import Header from 'components/structure/header'
import Main from 'components/structure/main'
import InfiniteScroll from 'components/ui/infinite-scroll'
import ArticleExcerptAlt from 'components/article/article-excerpt-alt'

const Index = () => {

  // Get Contentful ids of user accessible subscriptions from userContext
  const { userState } = useContext(UserContext)

  // Get list of hidden article ids
  const { hidden } = useContext(SiteContext)

  // Set state items
  const [subscriptionIds, setSubscriptionIds] = useState()

  // Update state of products reliant on user context (promise)
  useEffect(() => {
    if (userState.accessibleFulfillment && userState.accessibleFulfillment.subscription)
      setSubscriptionIds(userState.accessibleFulfillment.subscription.filter(item => !item.hide).map(item => item.cfId).join(','))
  }, [userState.accessibleFulfillment])

  // Params for infinite scroll
  const paramsInfiniteScroll = {
    order: '-fields.postDate',
    'fields.subscription.sys.id[in]': subscriptionIds,
    'fields.articleCategory.sys.id[nin]': hidden.join(','),
    'fields.hideFromNew[ne]': true
  }

  // Build infinite scroll
  let infinteScroll
  if (subscriptionIds) {
    infinteScroll = (
      <InfiniteScroll
        element=<ArticleExcerptAlt />
        url='/api/article'
        params={paramsInfiniteScroll}
      />
    )
  }

  // Params for spotlight
  const paramsSpotlight = {
    'fields.domain': process.env.NEXT_PUBLIC_ASSET_DOMAIN,
    'select': 'fields.featureContent',
    include: 3
  }

  // Build spotlight
  let spotlight
  if (subscriptionIds) {
    spotlight = (
      <Spotlight
        url='/api/website'
        params={paramsSpotlight}
      />
    )
  }

  return (
    <>
      <Meta />
      <Header />
      <Main>
        <div className="grid-container">
          <div className="grid-x grid-margin-x">
            <div className="cell small-12 medium-large-4 show-for-medium-large">
              <aside className="home__aside">
                <h2 className="grid-section-header">Spotlight</h2>
                <section className="grid-section-wrapper small bottom-collapse no-padding--bottom">
                  {spotlight}
                </section>
              </aside>
            </div>
            <div className="cell small-12 medium-large-8">
              <div className="home__main">
                <h2 className="grid-section-header">Most Recently Published</h2>
                <section id="section" className="grid-section-wrapper secondary bottom-collapse">
                  <div className="recent-articles">
                    {infinteScroll}
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </Main>
      <Footer />
    </>
  )
}

export default Index